import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ClientApp } from '../models/clientApp';
import { ToasterService } from './toaster.service';
import { ConfigService } from '../utils/config.service';
import { ClientInfo } from '../models/clientInfo';
import { PathService } from '../utils/path.service';
import {Resource} from '../models/resource';

@Injectable()
export class ClientApplicationService {

  // Define the routes we are going to interact with
  private clientappUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/clientapplications/');
  private resourceUrl = this.path.Combine(this.configService.apiRootUrl, 'api/v0/resources/');

  dataChange: BehaviorSubject<ClientApp[]> = new BehaviorSubject<ClientApp[]>([]);

  // Temporarily stores data from dialogs
  dialogData: any;

  constructor(
      private httpClient: HttpClient,
      private configService: ConfigService,
      private toasterService: ToasterService,
      private path: PathService) { }

  get data(): ClientApp[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  updateTenant(clientApp: ClientApp): void {
    this.dialogData = clientApp;
  }


  getResources(): Observable<Resource[]>{
    const url = this.resourceUrl;
    return this.httpClient.get<Resource[]>(url);
  }

  // Read Methods
  getAll(): void {
    const url = this.clientappUrl;
    this.httpClient.get<ClientApp[]>(url).subscribe(data => {
      this.dataChange.next(data);
    },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      });
  }
  getClientApplication(id: String): Observable<ClientApp> {
    const url = this.clientappUrl + id;
    return this.httpClient.get<ClientApp>(url);
  }

  getClientInfo(id: String): Observable<ClientInfo>{
    const url = this.clientappUrl + 'client-secret?id=' + id;
    return this.httpClient.get<ClientInfo>(url);
  }

  // Create Methods
  addClientApplication(clientApp: ClientApp): void {
    const url = this.clientappUrl;
    
    this.httpClient.post<ClientApp>(url, clientApp).subscribe(data => {
      this.dialogData = clientApp;
      this.toasterService.success(`Application ${data.name} Added`);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, 'Unable to add Client Application ');
      });
  }

  // Update Methods
  updateClientApp(clientApp: ClientApp): void {
    const url = this.clientappUrl + clientApp.id;
    this.httpClient.put(url, clientApp).subscribe(data => {
      this.dialogData = clientApp;
      this.toasterService.success(`Application ${clientApp.name} Updated`);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, `Unable to update ${clientApp.name} Client Application `);
      }
    );
  }

  regenerateClientSecret(clientApp: ClientApp): void {
    const url = this.clientappUrl + clientApp.id + '/client-secret';
    
    this.httpClient.post<ClientApp>(url, clientApp).subscribe(data => {
      this.dialogData = clientApp;
      this.toasterService.success(`Application ${clientApp.name} Secret Regenerated`);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, `Unable to Generate new Secret for Client ${clientApp.name}`);
      });
  }

  deleteClient(clientApp: ClientApp): void {
    const url = this.clientappUrl + clientApp.id;
    
    this.httpClient.delete<ClientApp>(url).subscribe(data => {
      this.toasterService.success(`Application ${clientApp.name} Deleted`);
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.name, err.message, `Unable to Delete Client ${clientApp.name}`);
      });
  }
}
