<div class="container">
  <h3 mat-dialog-title>Edit Client Application Resources</h3>
  <form (ngSubmit)="submit" #formControl="ngForm">
    <div class="row">
      <div class="col-md-12">
        <mat-list>
          <mat-list-item *ngFor="let resource of data.resources; let i = index">
          <mat-checkbox [value]="resource" (change)="onResourceChange($event)" [checked]="isResourceSet(resource)">{{ resource }}</mat-checkbox>
          </mat-list-item>
        </mat-list>
        <mat-error *ngIf="!isValidResourcesCount()">At least 1 Resource must be selected</mat-error>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!isValidResourcesCount()"
        [mat-dialog-close]="1" (click)="confirmAdd()">Add</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>
