<div class="container">
  <h3 mat-dialog-title>Edit Enterprise for the User</h3>
  <form (ngSubmit)="submit" #formControl="ngForm">
    <div class="row">
      <div class="col-md-12">
       <mat-form-field class="example-full-width">
        <input matInput #input placeholder="Enterprise" [(ngModel)]="data.tenant.tenantName" name ="tenantName" disabled />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-list>
          <mat-list-item *ngFor="let role of data.roles; let i = index">
          <mat-checkbox [value]="role.value" (change)="onRoleChange($event)" [checked]="isRoleSet(role)">{{ role.name }}</mat-checkbox>
          </mat-list-item>
        </mat-list>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button class="btn btn-danger pull-right" [type]="submit"
        [mat-dialog-close]="1" (click)="confirmAdd()">Add</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>
