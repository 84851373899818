import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, fromEvent, merge, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import { AddUserDialogComponent } from './addUser.dialog.component';

import {UserService } from '../services/user.service';
import {TenantService} from '../services/tenant.service';
import {User} from '../models/user';
import {Tenant } from '../models/tenant';
import { EditUserDialogComponent } from './editUser.dialog.component';
import { DeleteUserDialogComponent } from './deleteUser.dialog.component';

import { ToasterService } from '../services/toaster.service';
import { ReasignUserDialogComponent } from './reassignUser.dialog.component';
import { ConfigService } from '../utils/config.service';
import { PathService } from '../utils/path.service';

import { UserRole } from '../models/userRole';
import { FormControl } from '@angular/forms';
import { PagedSearchResult } from '../models/pagedSearchResult';

@Component({
  selector: 'app-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns = ['userName', 'emailAddress', 'lastName', 'firstName', 'tenantName', 'status',  'actions'];
  dataSource: User[] = [];

  pageSizes = [5, 10, 25, 100];
  totalCount = 0;
  isLoading = true;
  limitReached = false;
  
  id: string;
  tenants: Tenant[];
  roles: UserRole[];

  destroy$ = new Subject<void>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchKeywordFilter = new FormControl();

  constructor(public httpClient: HttpClient,
              public dialog: MatDialog,
              public userService: UserService,
              public tenantService: TenantService,
              private configService: ConfigService,
              private toasterService: ToasterService,
              private path: PathService
              ) {}

  ngOnInit(): void {
    this.userService.getRoles().pipe(takeUntil(this.destroy$)).subscribe(x => this.roles = x);
    this.tenantService.getTenants().pipe(takeUntil(this.destroy$)).subscribe(x => this.tenants = x);
   }
  
  ngAfterViewInit() {
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => (this.paginator.pageIndex = 0));   // on sort order change, reset back to the first page.
    this.searchKeywordFilter.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => (this.paginator.pageIndex = 0)); // on filter change, reset back to the first page

    merge(this.searchKeywordFilter.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)), this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          var filterValue = this.searchKeywordFilter.value == null ? '' : this.searchKeywordFilter.value;
          return this.userService
            .searchUsers(
              filterValue,
              this.sort.active,
              this.sort.direction,
              this.paginator.pageIndex + 1,
              this.paginator.pageSize
            )
        }),
        map((data) => {
          this.isLoading = false;
          this.limitReached = data === null;

          if (data === null) {
            return [];
          }

          this.totalCount = data.totalItemCount;
          return data.items;
        }),
        catchError((error: HttpErrorResponse) => { 
          this.toasterService.error(error.name, error.message, "Unable to retrieve users");
          return of([]); 
        })
      )
      .subscribe((data) => (this.dataSource = data));
  }

  refresh() {
    this.paginator.pageIndex = 0;
    this.searchKeywordFilter.setValue('');
  }

  showEdit(user: User){
    return !user.isTerminated;
  }

  showDelete(user: User){
    return !user.isTerminated;
  }


  addNew() {

    const user = new User();
    user.roles = [];

    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      autoFocus: false,
      data: {
            user,
            tenantList: this.tenants,
            roles: this.roles
          }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === 1) {
        this.refresh();
      }
    });
  }

   startEdit(user: User) {

    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      data: {
          user,
          roles: this.roles,
          tenantList: this.tenants,
         }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === 1) {
        this.refresh();
      }
    });
  }

  delete(user: User){
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      data: {user }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === 1) {
        this.refresh();
      }
    });
  }

  reassignUser(user: User){
    const dialogRef = this.dialog.open(ReasignUserDialogComponent, {
      autoFocus: false,
      data: {
        user,
        tenantList: this.tenants,
        roles: this.roles
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (result === 1) {
        this.refresh();
      }
    });
  }

  getTenantDetails(user: User) {
    let displayTenant :string = '';
    user.userTenants.forEach( x => displayTenant += x.tenantName + ":" + x.roles.join() + '\n');

    return displayTenant;
  }

  ngOnDestroy(): void{
    this.destroy$.next();
    this.destroy$.complete();
  }
}
