export class ClientApp {
    id: string;
    tenantId: string;
    unitId: string;
    name: string;
    resourceNames: string[];
    isActive: boolean;
    tenantName: string;
    unitName: string;
  }
