import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {UserService} from '../services/user.service';
import {FormControl, Validators} from '@angular/forms';
import { UserRole } from '../models/userRole';

@Component({
  selector: 'app-edit-user.dialog',
  templateUrl: './editUserTenant.dialog.component.html',
  styleUrls: ['./editUserTenant.dialog.component.css']
})
export class EditUserTenantDialogComponent {

  selectedTenants: string[];
  userTenants: string[];

  constructor(public dialogRef: MatDialogRef<EditUserTenantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService) {
      this.userTenants = data.tenants;
      
    }
    formControl = new FormControl('', [
      Validators.required
      // Validators.email,
    ]);

    getErrorMessage() {
      return this.formControl.hasError('required') ? 'Required field' :
        this.formControl.hasError('email') ? 'Not a valid email' :
          '';
    }

    onRoleChange(event) {
      if (event.checked) {
        this.data.tenant.roles.push(event.source.value);
      } else {
        const index = this.data.tenant.roles.indexOf(event.source.value, 0);
        if (index > -1) {
          this.data.tenant.roles.splice(index, 1);
        }
      }
    }

    submit() {
    // empty stuff
    }

    isRoleSet(role: UserRole) {
      let result = false;

      this.data.tenant.roles.forEach(element => {
        if ( element == role.value) {
          result = true;
        }
      });

      return result;
    }


    onNoClick(): void {
      this.dialogRef.close();
    }

    confirmAdd() : void {
      this.dialogRef.close(
        { data: {
                 tenant:this.data.tenant
                }
        })
    }
}
