<div class="container">
  <h3 mat-dialog-title>Add User</h3>

  <form (ngSubmit)="submit" [formGroup]="addUserForm">

    <div class="row">
      <div class="col-md-12">
      <mat-form-field class="example-full-width">
        <input type="text" placeholder="Enterprise" matInput [formControl]="tenantControl" [matAutocomplete]="auto" required>
        <mat-autocomplete (optionSelected)="onTenantChanged($event)" #auto="matAutocomplete" [displayWith]="displayTenantName">
          <mat-option *ngFor="let t of selectedTenants | async" [value]="t">{{ t.name }}</mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="tenantControl.invalid"> Select Enterprise </mat-error>
      </mat-form-field>
    </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input matInput #input placeholder="Username" [(ngModel)]="newUser.userName" name="userName" formControlName="userName" required />
          <mat-error *ngIf="addUserForm.value.userName">Field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input type="email" matInput #input placeholder="Email Address" [(ngModel)]="newUser.emailAddress" name="emailAddress" formControlName="email"
            required email />
          <mat-error *ngIf="addUserForm.value.email"> Invalid Email </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input matInput #input placeholder="First Name" [(ngModel)]="newUser.firstName" name="firstName" formControlName="firstName" required />
          <mat-error *ngIf="addUserForm.value.firstName">Field is required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input matInput #input placeholder="Last Name" [(ngModel)]="newUser.lastName" name="lastName" formControlName="lastName" required />
          <mat-error *ngIf="addUserForm.value.lastName">Field is required</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="example-full-width">
          <input type="email" matInput #input placeholder="Phone Number" [(ngModel)]="newUser.phoneNumber" name="phoneNumber" formControlName="phoneNumber" />
          <mat-error *ngIf="addUserForm.value.phoneNumber"> Invalid Phone Number </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row"><div class="col-md-12">
    <mat-list>
      <mat-list-item *ngFor="let role of data.roles; let i = index">
        <mat-checkbox [value]="role.value" (change)="onRoleChange($event)">{{ role.name }}</mat-checkbox>
      </mat-list-item>
    </mat-list>
  </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button class="btn btn-danger pull-right" [type]="submit" [disabled]="!addUserForm.valid || !tenantControl.valid"
        [mat-dialog-close]="1" (click)="confirmAdd()">Save</button>
      <button mat-button (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>
