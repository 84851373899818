import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {UserTenant} from '../models/userTenant';
import { Observable } from 'rxjs';
import { Tenant } from '../models/tenant';
import { map, startWith } from 'rxjs/operators';
import { requiredSelection } from '../shared/validators';


@Component({
  selector: 'app-add-user.dialog',
  templateUrl: './addUserTenant.dialog.component.html',
  styleUrls: ['./addUserTenant.dialog.component.css']
})
export class AddUserTenantDialogComponent implements OnInit {

  selectedTenantId: any;
  selectedtenantName: string;
  userTenants: UserTenant[];
  selectedRoles :string[];
  userTenant : UserTenant;
  selectedTenants: Observable<Tenant[]>;

  constructor(public dialogRef: MatDialogRef<AddUserTenantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService) { }

    formControl = new FormControl('', [
      Validators.required
    ]);
    tenantControl = new FormControl('', [
      requiredSelection
    ]);

    ngOnInit(): void {
      this.selectedRoles = [];
      this.userTenants = this.data.user.userTenants;
      this.userTenant = new UserTenant;
      this.selectedTenants = this.tenantControl.valueChanges.pipe(
        startWith(''),
        map(value => {
           return value && typeof value === 'string' ? this.filterTenants(value) : this.data.tenantList;
        }),
      );
    }

    getErrorMessage() {
      return this.formControl.hasError('required') ? 'Required field' :
        this.formControl.hasError('email') ? 'Not a valid email' :
          '';
    }

    displayTenantName(tenant?: Tenant): string | undefined {
      return tenant ? tenant.name : undefined;
    }
  
    filterTenants(value: string) { 
      let filter = value?.toLowerCase();
      let currValues: Tenant[] = this.data.tenantList;
      return currValues.filter(option => option.name.toLowerCase().includes(filter));
    }

    onTenantChanged(event: any) {
      this.userTenant.tenantId = event.option.value.id;
      this.userTenant.tenantName = event.option.value.name;
    }

    onRoleChange(event) {
      if (event.checked) {
        this.selectedRoles.push(event.source.value);
      } else {
        const index = this.selectedRoles.indexOf(event.source.value, 0);
        if (index > -1) {
          this.selectedRoles.splice(index, 1);
        }
      }
    }

    submit() {
    // empty stuff
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    confirmAdd() : void {
      if (this.ifTenantExists())
      {
        //TODO change this it does not show as other error messages.
        alert('Enterprise ' + this.userTenant.tenantName + ' already exists for the user. Please edit enterprise if you wish to change roles.')
        return;
      }
      
      this.userTenant.roles = this.selectedRoles;
      this.data.user.userTenants.push(this.userTenant);
    }

    ifTenantExists() {
      let result = false;
      let exisingUserTenants : UserTenant[] = this.data.user.userTenants;
      exisingUserTenants.forEach(userTenant => {
        if (userTenant.tenantId == this.userTenant.tenantId)
        {
          result = true;
        }
      })
      return result;
    }
}
