import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatDialog} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {UserService} from '../services/user.service';
import {FormControl, Validators} from '@angular/forms';
import { UserRole } from '../models/userRole';

@Component({
  selector: 'edit-client-application-resources.dialog',
  templateUrl: './edit-client-application-resources.dialog.component.html',
  styleUrls: ['./edit-client-application-resources.dialog.component.css']
})
export class EditClientApplicationResourcesDialogComponent {

  userResources: string[] = [];

  constructor(public dialogRef: MatDialogRef<EditClientApplicationResourcesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService) {
      this.userResources = this.data.selectedResources;
    }

  submit() {
    // empty stuff
  }

  onResourceChange(event) {
    if (event.checked) {
      this.userResources.push(event.source.value);
    } else {
      const index = this.userResources.indexOf(event.source.value, 0);
      if (index > -1) {
        this.userResources.splice(index, 1);
      }
    }
  }

  isResourceSet(resource: string) {
    let result = false;

    this.userResources.forEach(el => {
      if (el == resource) {
        result = true;
      }
    });
    return result;
  }

  isValidResourcesCount() {
    return this.userResources.length > 0;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmAdd() : void {
    this.dialogRef.close(
      { data: {
                resources: this.userResources
              }
      })
  }
}
