import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { CopyClipboardModule } from './utils/copy-clipboard.module';

// app config
import { keycloakInitializer } from './utils/keycloak-init';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { ConfigService } from './utils/config.service';
import { AuthGuard } from './services/authguard.service';

// angular material
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, } from '@angular/material/dialog';
import { MatFormFieldModule, } from '@angular/material/form-field';
import { MatInputModule, } from '@angular/material/input';
import { MatSelectModule, } from '@angular/material/select';
import { MatTableModule, } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatAutocompleteModule } from '@angular/material/autocomplete'

// app
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientApplicationListComponent } from './client-applications/client-application-list.component';
import { AddClientApplicationDialogComponent } from './client-applications/addApplication.dialog.component';
import {ClientSecretDialogComponent } from './client-applications/client-secret.dialog.component';
import { RegenerateClientSecretComponent } from './client-applications/regenerate-client-secret.dialog.component';
import { DeleteClientComponent } from './client-applications/delete-client.dialog.component';
import { EditClientApplicationResourcesDialogComponent } from './client-applications/edit-client-application-resources.dialog.component';

import { TenantsListComponent } from './tenants/tenants-list.component';
import { TenantComponent } from './tenants/tenant.component';
import { EditTenantDialogComponent } from './tenants/editTenant.dialog.component';
import { AddTenantDialogComponent } from './tenants/addTenant.dialog.component';
import { AddUnitDialogComponent } from './tenants/addUnit.dialog.component';
import { EditUnitDialogComponent } from './tenants/editUnit.dialog.component';
import { UserListComponent } from './users/user-list.component';
import { AddUserDialogComponent } from './users/addUser.dialog.component';
import { EditUserDialogComponent } from './users/editUser.dialog.component';
import { EditUserTenantDialogComponent } from './users/editUserTenant.dialog.component';
import { AddUserTenantDialogComponent } from './users/addUserTenant.dialog.component';
import { DeleteUserTenantDialogComponent } from './users/deleteUserTenant.dialog.component';
import { DeleteUserDialogComponent } from './users/deleteUser.dialog.component';
import { ReasignUserDialogComponent } from './users/reassignUser.dialog.component';
import { ImportTenantsDialogComponent } from './tenants/importTenants.dialog.component';
import { UserEventsListComponent } from './userevents/userEvents-list.component';

// services
import { ClientApplicationService } from './services/client-application.service';
import { TenantService } from './services/tenant.service';
import { ToasterService } from './services/toaster.service';
import { UserService } from './services/user.service';
import { ConfigServiceProvider } from './utils/config.service.provider';
import { LoggingServiceProvider } from './utils/logging.provider';
import { LoggingService } from './utils/logging.service';
import { UserEventService} from './services/user-event.service';

// layouts
import {SidebarComponent } from './components/sidebar/sidebar.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {FooterComponent} from './components/footer/footer.component';
import { DashboardService } from './services/dashboard.service';
import { AbsoluteValuePipe } from './utils/absolute-value.pipe';
import { TrimPipe} from './utils/trim.pipe';
import { PathService } from './utils/path.service';
import { SanitizePipe } from './utils/sanitize.pipe';

@NgModule({
  declarations: [
    AppComponent,
    TenantsListComponent,
    DashboardComponent,
    AddTenantDialogComponent,
    AddUnitDialogComponent,
    EditUnitDialogComponent,
    AddClientApplicationDialogComponent,
    AddUserDialogComponent,
    EditUserDialogComponent,
    EditUserTenantDialogComponent,
    AddUserTenantDialogComponent,
    DeleteUserTenantDialogComponent,
    TenantComponent,
    EditTenantDialogComponent,
    ClientApplicationListComponent,
    ClientSecretDialogComponent,
    RegenerateClientSecretComponent,
    DeleteClientComponent,
    EditClientApplicationResourcesDialogComponent,
    UserListComponent,
    DeleteUserDialogComponent,
    ReasignUserDialogComponent,
    ImportTenantsDialogComponent,
    UserEventsListComponent,

    SidebarComponent,
    NavbarComponent,
    FooterComponent,

    // pipes
    AbsoluteValuePipe,
    TrimPipe,
    SanitizePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    CopyClipboardModule,

    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    KeycloakAngularModule,



    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    MatDialogModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatListModule,
    MatAutocompleteModule
  ],
  providers: [
    ConfigService,
    ConfigServiceProvider,
    ToasterService,
    PathService,
    LoggingServiceProvider,
    LoggingService,
    SanitizePipe,


    // app providers
    TenantService,
    ClientApplicationService,
    UserService,
    UserEventService,
    DashboardService,

    // Keycloak
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    AuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: keycloakInitializer,
      useClass: ConfigService,
      multi: true,
      deps: [KeycloakService, ConfigService, LoggingService],
    },
  ],
  bootstrap: [AppComponent],
  exports: [
    AbsoluteValuePipe
  ]
})
export class AppModule { }
