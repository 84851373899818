import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy } from '@angular/core';
import { ClientApplicationService } from '../services/client-application.service';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Tenant } from '../models/tenant';
import { BehaviorSubject, observable, Subject } from 'rxjs';
import { Unit } from '../models/unit';
import { Observable } from 'rxjs/internal/Observable';
import { TenantService } from '../services/tenant.service';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { requiredSelection } from '../shared/validators';
import { EditClientApplicationResourcesDialogComponent } from './edit-client-application-resources.dialog.component';

@Component({
  selector: 'app-add-application.dialog',
  templateUrl: './addApplication.dialog.component.html',
  styleUrls: ['./addApplication.dialog.component.css']
})

export class AddClientApplicationDialogComponent implements OnDestroy {

  selectedTenant: Observable<Tenant>;
  units: BehaviorSubject<Unit[]>;
  selectedTenants: Observable<Tenant[]>;
  newApplication: any = {};

  tenantSubject: Subject<Tenant> = new Subject<Tenant>();

  destroy$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<AddClientApplicationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private tenantService: TenantService,
              public appService: ClientApplicationService,
              public dialog: MatDialog,) {

  }

  formControl = new FormControl('', [
    Validators.required
  ]);
  tenantControl = new FormControl('', [
    requiredSelection
  ]);
  pharmacyControl = new FormControl('', [
    Validators.required
  ]);

  ngOnInit() {
    this.units = new BehaviorSubject(null);
    this.selectedTenants = this.tenantControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return value && typeof value === 'string' ? this.filterTenants(value) : this.data.tenantList;
      }),
    );
  }

  // event handler for the select element's change event
  onTenantChanged(event: any) {
    let tenantId = event.option.value.id;
    this.newApplication.tenantId = tenantId;
    this.selectedTenant = this.getTenant(tenantId);

    this.selectedTenant.subscribe(value => {

      if (!value.units){
        value.units = new Unit[0];
      }

      this.units.next(value.units);
    });

    this.newApplication.unitId = ''
    this.pharmacyControl.setErrors({'incorrect': true});
  }

  displayTenantName(tenant?: Tenant): string | undefined {
    return tenant ? tenant.name : undefined;
  }

  getTenant(id: string): Observable<Tenant> {
    return this.tenantService.getTenant(id).pipe(map(ts => ts));
  }

  filterTenants(value: string) { 
    let filter = value?.toLowerCase();
    let currValues: Tenant[] = this.data.tenantList;
    return currValues.filter(option => option.name.toLowerCase().includes(filter));
  }

  getApplicationResources() {
    return this.newApplication?.resourceNames?.join(",");
  }

  startApplicationResourcesEdit() {
    const dialogRef = this.dialog.open(EditClientApplicationResourcesDialogComponent, {
      data: {
          selectedResources: this.newApplication?.resourceNames ?? [],
          resources: this.data.resourceList
         }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      console.log(result.data.resources)
      this.newApplication.resourceNames = result.data.resources;
    });
    return;
  }

  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(): void {
    this.appService.addClientApplication(this.newApplication);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
